import { Component, Key, ReactChild, ReactFragment, ReactPortal } from "react";
import BreadCrumb from "../BreadCrumb";
import ToggleSwitch from "../ToggleSwitch";
import { Link, withRouter, useHistory, useRouteMatch } from "react-router-dom";
import DatePicker from "../DatePicker";
import BookingSummary from "./BookingSummary";
import { daysBetween } from "../../utils/DateFormat";
import store from "../../redux/store";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import Modal from "../Modal";
import { ConsoleLogger } from "@aws-amplify/core";
import ContactProfileCard, { CorporateContacts } from "./ContactProfileCard";
import { BatchSnoozeAlarmResponse } from "aws-sdk/clients/ioteventsdata";
import { bool } from "aws-sdk/clients/signer";

interface ManualInfoState {
  // units
  // unit_list: string[];
  units: any[]; // TODO: The type should be an array of Unit objects.
  unit_id: number;
  // unit_ids: number[];

  // toggle options
  manual_invoicing: boolean;
  hard_checkout: boolean;
  auto_hst: boolean;
  highrisk: boolean; // TODO: Rename this to high_risk_client.

  // stay duration details
  // booking_unit: string; // TODO: isn't this just bookingData.suite_name?
  check_in: Date;
  check_out: Date;
  pricing_type: string;
  cleaning_frequency: string;
  rate: number;
  pets: boolean; // TODO: Rename this to pet_fee.
  pet_fee: number;
  parking_fee: number; // TODO: Rename this to parking_fee.
  cleaning_fee: number;

  // agreement documents
  agreement_type: string;

  // notes section
  booking_notes: string;

  // deposit information
  deposit: number;
  deposit_expiry: number;

  // corporate bookings
  admin_id: number;
  admin_ids: number[];
  admin_contact: string;
  corporate: boolean;
  admin_list: string[];
  corp_list: string[];
  corporate_name: string;
  corporate_id: number;
  corporate_ids: number[];
  corporate_deduction: number;
  cleaning_list: string[];
  cleaning_id: number;
  cleaning_ids: number[];
  cleaning_contact: string;
  finance_list: string[];
  finance_id: number;
  finance_ids: number[];
  finance_contact: string;
  corporates: any[];
  var_com_pol: any[];
  tenant_id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;

  parkingRequired: boolean;
  parking_spot_id?: number;
  parking_notes?: string;

  activeParkingSpots: any;
  rentalsUnitedToggle: boolean;
  rentalsUnitedReservationID: number;
}

type bookingBody = {
  unit_id: number;
  check_in: Date;
  check_out: Date;
  pricing_type: string;
  rate: number;
  cleaning_frequency: string;
  pets: boolean;
  pet_fee: number;
  auto_hst: boolean;
  cleaning_fee: number;
  manual_invoicing: boolean;
  hard_checkout: boolean;
  parking_fee: number;
  agreement_type: string;
  booking_notes: string;
  deposit_expiry: number;
  deposit: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  rentalsUnitedReservationID: number;
};
const corporateVariablePolicy: Map<number, boolean> = new Map([]);

class ManualInfo extends Component<any, ManualInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tenant_id: -1,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      // units
      // unit_list: [],
      units: [],
      unit_id: -1,
      // unit_ids: [],

      // toggle options
      manual_invoicing: false,
      hard_checkout: false,
      auto_hst: false,
      highrisk: false, // TODO: Rename this to high_risk_client.

      // stay duration details
      // booking_unit: "",
      check_in: new Date(),
      check_out: new Date(),
      pricing_type: "nightly",
      cleaning_frequency: "none",
      rate: 0,
      pets: false, // TODO: Rename this to pet_fee.
      pet_fee: 0,
      parking_fee: 0, // TODO: Rename this to parking_fee.
      cleaning_fee: 0,

      // agreement documents
      agreement_type: "",

      // notes section
      booking_notes: "",

      // deposit information
      deposit: 0,
      deposit_expiry: -1,

      // corporate bookings
      admin_id: null,
      admin_ids: [],
      admin_contact: "",
      admin_list: [],
      corp_list: [],
      corporate: false,
      corporate_name: "",
      corporate_id: null,
      corporate_ids: [],
      corporate_deduction: 0,
      cleaning_list: [],
      cleaning_id: null,
      cleaning_ids: [],
      cleaning_contact: "",
      finance_id: null,
      finance_list: [],
      finance_ids: [],
      finance_contact: "",
      corporates: [],
      var_com_pol: [],

      parkingRequired: false,
      parking_spot_id: -1,
      parking_notes: "",

      activeParkingSpots: null,
      rentalsUnitedToggle: false,

      rentalsUnitedReservationID: null,
    };
  }

  updateState = async () => {
    if (
      this.state.unit_id &&
      daysBetween(
        this.state.check_in.toString(),
        this.state.check_out.toString()
      ) > 0 &&
      this.state.rate > 0
    ) {
      let newBooking = {
        check_in: this.state.check_in,
        check_out: this.state.check_out,
        unit_id: this.state.unit_id,
        monthly_rate: this.state.rate,
        cleaning_frequency: this.state.cleaning_frequency.toLowerCase(),
        created_by: "",
        pets: false,
        booking_type: this.state.pricing_type.toLowerCase(),
        booking_notes: this.state.booking_notes,
        parking_requested: this.state.parkingRequired,
        deposit_expiry: this.state.deposit_expiry,
        deposit: this.state.deposit,
        pet_fee: this.state.pet_fee,
        apply_hst: this.state.auto_hst,
        cleaning_fee: this.state.cleaning_fee,
        parking_fee: this.state.parking_fee,
        hard_checkout: this.state.hard_checkout,
        rentalsUnitedReservationID: this.state.rentalsUnitedReservationID,
      };
      if (this.state.pricing_type.toLowerCase() === "nightly") {
        newBooking.monthly_rate = this.state.rate * 30;
        newBooking.booking_type = "thirty_day";
      }
      store.dispatch({
        type: "setBooking",
        booking: newBooking,
      });
    }
  };

  componentDidMount = async () => {
    let allUnits = await fetchGetJSON(Constants.API_PATH_UNIT_GET_ALL);
    const activeUnits = allUnits.data.filter(
      (unit: { active: any }) => unit.active
    );

    const allActiveParkingSpots = await fetchGetJSON(
      Constants.API_PATH_PARKING_SPOT_GET_ALL_ACTIVE
    );

    this.setState({
      activeParkingSpots: allActiveParkingSpots.data.sort(
        (parkingSpotOne: any, parkingSpotTwo: any) =>
          parkingSpotOne.parking_spot_location.localeCompare(
            parkingSpotTwo.parking_spot_location
          )
      ),
    });

    if (this.state.parking_spot_id == -1)
      this.setState({
        parking_spot_id: this.state.activeParkingSpots[0]?.id,
      });

    const allCorp = await fetchGetJSON(
      `${Constants.API_PATH_CORP_GET_ALL}?active=true`
    );
    // let unitList = [];
    // for (let i = 0; i < activeUnits.length; i++) {
    //   unitList.push(activeUnits[i].suite_name);
    // }

    this.setState({
      // unit_list: unitList,
      units: activeUnits,
      unit_id: activeUnits[0].id,
      // unit_ids: allUnits.data.map((unit: any) => unit.id),
      // booking_unit: unitList[0],
      corporates: allCorp.data,
    });
    let allCorps = allCorp.data.sort((a: any, b: any) =>
      a.corporate_name.toLowerCase() > b.corporate_name.toLowerCase() ? 1 : -1
    );
    let corpList = [];
    let corpIds = [];
    let varComPolList = [];

    if (allCorps) {
      for (let i = 0; i < allCorps.length; i++) {
        let cur_corp = {
          name: allCorps[i].corporate_name,
          id: allCorps[i].id,
          variable_communication_policy:
            allCorps[i].variable_communication_policy,
        };
        corpList.push(cur_corp.name);
        corpIds.push(cur_corp.id);
        varComPolList.push(cur_corp.variable_communication_policy);
        corporateVariablePolicy.set(
          allCorps[i].id,
          allCorps[i].variable_communication_policy
        );

        //Set a default value:
        if (i === 0) {
          //very first corporate
          this.setState({ corporate_id: cur_corp.id });
          const corporateContacts = await fetchGetJSON(
            `${Constants.API_PATH_CORP_CONTACT_GET_ALL}?corporate_id=${this.state.corporate_id}`
          );
          this.getCorporate(corporateContacts);
        }
      }
    }
    // This is the template so that the page can restore data in the future
    // There might be an issue with saving the rate value since it might be multiplied by 30
    // Issues: Manual Invoicing and Hard Check out are never used or added to the redux
    let bookingData = store.getState().bookingReducer.newBooking;
    let tenantData = store.getState().bookingReducer.bookingTenant;
    // console.log(tenantData);
    let newBooking: bookingBody;
    if (tenantData.id) {
      if (bookingData) {
        newBooking = {
          // booking_unit: activeUnits.find((unit: any) => unit.id === bookingData.unit_id).suite_name,
          unit_id: bookingData.unit_id,
          check_in: bookingData.check_in,
          check_out: bookingData.check_out,
          pricing_type: bookingData.booking_type,
          rate: bookingData.monthly_rate,
          cleaning_frequency: bookingData.cleaning_frequency,
          pets: bookingData.pet_fee ? true : false,
          pet_fee: bookingData.pet_fee,
          auto_hst: bookingData.apply_hst,
          cleaning_fee: bookingData.cleaning_fee,
          manual_invoicing: false,
          hard_checkout: false,
          parking_fee: bookingData.parking_fee,
          agreement_type: "",
          booking_notes: bookingData.booking_notes,
          deposit_expiry: bookingData.deposit_expiry,
          deposit: bookingData.deposit,
          rentalsUnitedReservationID: this.state.rentalsUnitedReservationID,
        };
      } else {
        newBooking = {
          // booking_unit: activeUnits.find((unit: any) => unit.id === this.state.unit_id).suite_name,
          unit_id: this.state.unit_id,
          check_in: this.state.check_in,
          check_out: this.state.check_out,
          pricing_type: this.state.pricing_type,
          rate: this.state.rate,
          cleaning_frequency: this.state.cleaning_frequency,
          pets: false,
          pet_fee: this.state.pet_fee,
          auto_hst: this.state.auto_hst,
          cleaning_fee: this.state.cleaning_fee,
          manual_invoicing: this.state.manual_invoicing,
          hard_checkout: this.state.hard_checkout,
          parking_fee: this.state.parking_fee,
          agreement_type: this.state.agreement_type,
          booking_notes: this.state.booking_notes,
          deposit_expiry: this.state.deposit_expiry,
          deposit: this.state.deposit,
          rentalsUnitedReservationID: this.state.rentalsUnitedReservationID,
        };
      }
    } else {
      if (bookingData) {
        newBooking = {
          // booking_unit: activeUnits.find((unit: any) => unit.id === bookingData.unit_id).suite_name,
          unit_id: bookingData.unit_id,
          check_in: bookingData.check_in,
          check_out: bookingData.check_out,
          pricing_type: bookingData.booking_type,
          rate: bookingData.monthly_rate,
          cleaning_frequency: bookingData.cleaning_frequency,
          pets: bookingData.pet_fee ? true : false,
          pet_fee: bookingData.pet_fee,
          auto_hst: bookingData.apply_hst,
          cleaning_fee: bookingData.cleaning_fee,
          manual_invoicing: false,
          hard_checkout: false,
          parking_fee: bookingData.parking_fee,
          agreement_type: "",
          booking_notes: bookingData.booking_notes,
          deposit_expiry: bookingData.deposit_expiry,
          deposit: bookingData.deposit,
          first_name: tenantData.first_name ? tenantData.first_name : "N/A",
          last_name: tenantData.last_name ? tenantData.last_name : "N/A",
          email: tenantData.email ? tenantData.email : null,
          phone_number: tenantData.phone_number
            ? tenantData.phone_number
            : null,
          rentalsUnitedReservationID: this.state.rentalsUnitedReservationID,
        };
      } else {
        newBooking = {
          // booking_unit: activeUnits.find((unit: any) => unit.id === this.state.unit_id).suite_name,
          unit_id: this.state.unit_id,
          check_in: this.state.check_in,
          check_out: this.state.check_out,
          pricing_type: this.state.pricing_type,
          rate: this.state.rate,
          cleaning_frequency: this.state.cleaning_frequency,
          pets: false,
          pet_fee: this.state.pet_fee,
          auto_hst: this.state.auto_hst,
          cleaning_fee: this.state.cleaning_fee,
          manual_invoicing: this.state.manual_invoicing,
          hard_checkout: this.state.hard_checkout,
          parking_fee: this.state.parking_fee,
          agreement_type: this.state.agreement_type,
          booking_notes: this.state.booking_notes,
          deposit_expiry: this.state.deposit_expiry,
          deposit: this.state.deposit,
          first_name: tenantData.first_name ? tenantData.first_name : "N/A",
          last_name: tenantData.last_name ? tenantData.last_name : "N/A",
          email: tenantData.email ? tenantData.email : null,
          phone_number: tenantData.phone_number
            ? tenantData.phone_number
            : null,
          rentalsUnitedReservationID: this.state.rentalsUnitedReservationID,
        };
      }
    }

    this.setState(newBooking);
    this.setState({
      corporate_name: corpList.length !== 0 ? corpList[0] : "",
      corp_list: corpList,
      corporate_ids: corpIds,
      var_com_pol: varComPolList,
    });
  };

  handleCorporateToggle = () => {
    this.setState({ corporate: !this.state.corporate });
  };

  handleRentalsUnitedToggle = () => {
    this.setState({ rentalsUnitedToggle: !this.state.rentalsUnitedToggle });
  };

  toggleParking = () => {
    this.setState({
      parkingRequired: !this.state.parkingRequired,
    });
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleInputChange = async (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    await this.setState(stateObject);
    this.updateState();
  };

  // TODO: Refactor this function so that it doesn't use bind().
  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      returnObj[id] = !state;
      return returnObj;
    })();
    this.setState(stateObject);
    this.updateState();
  };

  handleHighRiskTenant = () => {
    this.setState(
      {
        highrisk: !this.state.highrisk,
      },
      () => {
        if (this.state.highrisk) {
          const { history } = this.props;
          history.push("/user/bookings");
        }
      }
    );
  };

  handleBack = () => {
    const { history } = this.props;
    if (history) {
      history.push("/user/bookings");
    }
  };

  handleCorporateChange = async (event: any) => {
    event.preventDefault();
    let corporateId = event.target.value
      ? event.target.value
      : this.state.corporate_id;
    let corporateName = "";
    for (let i = 0; i <= this.state.corporate_ids.length; i++) {
      if (this.state.corporate_ids[i] == corporateId) {
        corporateId = this.state.corporate_ids[i];
        corporateName = this.state.corp_list[i];
        this.setState({
          corporate_id: corporateId,
          corporate_name: corporateName,
        });
        const corporateContacts = await fetchGetJSON(
          `${Constants.API_PATH_CORP_CONTACT_GET_ALL}?corporate_id=${corporateId}&active=true`
        );
        this.getCorporate(corporateContacts);
        return;
      }
    }
  };

  getCorporate = (event: any) => {
    //reset info
    this.setState({
      admin_list: [],
      admin_ids: [],
      finance_list: [],
      finance_ids: [],
      cleaning_list: [],
      cleaning_ids: [],
    });

    let adminList = [];
    let adminIds = [];
    let financeList = [];
    let financeIds = [];
    let cleaningList = [];
    let cleaningIds = [];

    for (let i = 0; i < event.data.length; i++) {
      if (event.data[i].admin == true) {
        let cur_admin = {
          email: event.data[i].email,
          id: event.data[i].id,
        };
        adminList.push(cur_admin.email);
        adminIds.push(cur_admin.id);
        this.setState({
          admin_list: adminList,
          admin_ids: adminIds,
        });
      }
      if (event.data[i].finance == true) {
        let cur_finance = {
          email: event.data[i].email,
          id: event.data[i].id,
        };
        financeList.push(cur_finance.email);
        financeIds.push(cur_finance.id);
        this.setState({
          finance_list: financeList,
          finance_ids: financeIds,
        });
      }
      if (event.data[i].cleaning == true) {
        let cur_cleaning = {
          email: event.data[i].email,
          id: event.data[i].id,
        };
        cleaningList.push(cur_cleaning.email);
        cleaningIds.push(cur_cleaning.id);
        this.setState({
          cleaning_list: cleaningList,
          cleaning_ids: cleaningIds,
        });
      }
    }
  };

  handleAdminChange = (event: any) => {
    event.preventDefault();
    let adminId = event.target.value ? event.target.value : this.state.admin_id;
    let adminContact = "";
    for (let i = 0; i < this.state.admin_ids.length; i++) {
      if (this.state.admin_ids[i] == adminId) {
        adminId = this.state.admin_ids[i];
        adminContact = this.state.admin_list[i];
        this.setState({
          admin_id: adminId,
          admin_contact: adminContact,
        });
        return;
      }
    }
  };

  handleFinanceChange = async (event: any) => {
    event.preventDefault();
    let financeId = event.target.value
      ? event.target.value
      : this.state.finance_id;
    let financeContact = "";
    for (let i = 0; i < this.state.finance_ids.length; i++) {
      if (this.state.finance_ids[i] == financeId) {
        financeId = this.state.finance_ids[i];
        financeContact = this.state.finance_list[i];
        this.setState({
          finance_id: financeId,
          finance_contact: financeContact,
        });
        return;
      }
    }
  };

  handleCleaningChange = (event: any) => {
    event.preventDefault();
    let cleaningId = event.target.value
      ? event.target.value
      : this.state.cleaning_id;
    let cleaningContact = "";
    for (let i = 0; i < this.state.cleaning_ids.length; i++) {
      if (this.state.cleaning_ids[i] == cleaningId) {
        cleaningId = this.state.cleaning_ids[i];
        cleaningContact = this.state.cleaning_list[i];
        this.setState({
          cleaning_id: cleaningId,
          cleaning_contact: cleaningContact,
        });
        return;
      }
    }
  };

  createCorpItems() {
    let items = [];
    items.push(
      <option disabled selected>
        {" "}
        -- select an option --{" "}
      </option>
    );
    for (let i = 0; i < this.state.corp_list.length; i++) {
      items.push(
        <option
          key={this.state.corporate_ids[i]}
          value={this.state.corporate_ids[i]}
          selected={this.state.corporate_ids[i] === this.state.corporate_id}
        >
          {this.state.corp_list[i]}
        </option>
      );
    }
    return items;
  }

  createAdminItems() {
    let admin_items = [];
    for (let i = 0; i < this.state.admin_list.length; i++) {
      admin_items.push(
        <option
          key={this.state.admin_ids[i]}
          value={this.state.admin_ids[i]}
          selected={this.state.admin_ids[i] === this.state.admin_id}
        >
          {this.state.admin_list[i]}
        </option>
      );
    }
    return admin_items?.length > 0 ? (
      admin_items
    ) : (
      <option selected disabled>
        No Admin Contact for this Corporate
      </option>
    );
  }

  createFinanceItems() {
    let finance_items = [];
    for (let i = 0; i < this.state.finance_list.length; i++) {
      finance_items.push(
        <option
          key={this.state.finance_ids[i]}
          value={this.state.finance_ids[i]}
          selected={this.state.finance_ids[i] === this.state.finance_id}
        >
          {this.state.finance_list[i]}
        </option>
      );
    }
    return finance_items?.length > 0 ? (
      finance_items
    ) : (
      <option selected disabled>
        No Finance Contact for this Corporate
      </option>
    );
  }

  createCleaningItems() {
    let cleaning_items = [];
    for (let i = 0; i < this.state.cleaning_list.length; i++) {
      cleaning_items.push(
        <option
          key={this.state.cleaning_ids[i]}
          value={this.state.cleaning_ids[i]}
          selected={this.state.cleaning_ids[i] === this.state.cleaning_id}
        >
          {this.state.cleaning_list[i]}
        </option>
      );
    }
    return cleaning_items?.length > 0 ? (
      cleaning_items
    ) : (
      <option selected disabled>
        No Cleaning Contact for this Corporate
      </option>
    );
  }

  getPricingType() {
    if (this.state.pricing_type === "monthly") {
      return "Month";
    } else if (this.state.pricing_type === "nightly") {
      return "Night";
    } else if (this.state.pricing_type === "thirty_day") {
      return "30 Day";
    } else {
      alert("I don't recognize this pricing type!");
    }
  }

  getCorporateSectionLayout() {
    if (this.state.corporate) {
      return "opacity-100 translate-y-0";
    } else {
      return "opacity-0 transform  -translate-y-10 hidden ";
    }
  }

  render() {
    let dayCount = daysBetween(
      this.state.check_in.toString(),
      this.state.check_out.toString()
    );
    let fields;
    //If variable policy is true, then we load in options for corporation contacts
    if (corporateVariablePolicy.get(this.state.corporate_id)) {
      fields = (
        <div>
          <span className="text-gray-400 text-xs">Admin Contact</span>
          <select
            id="admin_id"
            // value={this.state.admin_id}
            onChange={this.handleAdminChange}
            className="block w-full text-sm  mt-1 mb-4 rounded-sm border-gray-100 border-2
            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            {this.createAdminItems()}
          </select>

          <span className="text-gray-400 text-xs">Finance Contact</span>
          <select
            id="finance_id"
            // value={this.state.finance_id}
            onChange={this.handleFinanceChange}
            className="block w-full text-sm  mt-1 mb-4 rounded-sm border-gray-100 border-2
            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            {this.createFinanceItems()}
          </select>

          <span className="text-gray-400 text-xs">Cleaning Contact</span>
          <select
            id="cleaning_id"
            // value={this.state.cleaning_id}
            onChange={this.handleCleaningChange}
            className="block w-full text-sm  mt-1 mb-4 rounded-sm border-gray-100 border-2
            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            {this.createCleaningItems()}
          </select>
        </div>
      );
    }

    return (
      <>
        <Modal
          title="High Risk Tenant?"
          content={
            '"Is this tenant high risk? ie. local"". ' +
            "If 'yes'" +
            ' = damage deposit and invoice needs to be sent manually"'
          }
          handleModalToggle={this.handleHighRiskTenant}
          confirmButton={this.handleHighRiskTenant}
          onoff={this.state.highrisk}
          yes={true}
        />
        <div className="  bg-white font-display lg:rounded-tl-lg ">
          <BreadCrumb
            rootPage="Bookings"
            subPage="New > Manual Booking > Info"
            sideButton=""
            buttonAction=""
          />
          <div className="fade-in px-10 mt-16 lg:mt-0 max-w-5xl pb-24">
            {/* Heading Info Area  */}
            <form>
              <div className="flex flex-row mb-6">
                <div className="flex flex-col w-full">
                  <h1 className="text-2xl px-2 pt-3 text-gray-700 font-black font-display ">
                    Booking Info
                  </h1>
                  <h2 className="px-2 font-normal font-display text-sm text-gray-500">
                    Continue with booking info.
                  </h2>
                </div>
              </div>
              <div className="pl-2 flex flex-col self-start w-52 mb-5">
                <label className="block  ">
                  <label className="block  ">
                    <span className="text-gray-400 text-xs">Units</span>
                    <select
                      id="unit_id"
                      value={this.state.unit_id}
                      onChange={this.handleInputChange}
                      className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2
                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                      {this.state.units.map((unit) => (
                        <option key={unit.id} value={unit.id}>
                          {" "}
                          {unit.suite_name}{" "}
                        </option>
                      ))}
                    </select>
                  </label>
                </label>
              </div>

              <div className="flex flex-row">
                <DatePicker
                  data={this.state}
                  handleInputChange={this.handleInputChange}
                  dayCount={dayCount}
                />
                <div className="flex flex-col ml-10">
                  <div
                    className=" flex flex-row  font-medium pl-3 py-1 text-gray-700  mt-1 rounded-sm
                      border-gray-100 border-2 "
                  >
                    <span>Pricing Type -</span>
                    <select
                      id="pricing_type"
                      onChange={this.handleInputChange}
                      value={this.state.pricing_type}
                      className=" mt-0 py-0 pl-1 pr-20 border-0 text-gray-500  border-gray-200
                        focus:ring-0 focus:border-black"
                    >
                      {/* <option value="monthly">Monthly Rate</option> */}
                      <option value="nightly">Nightly Rate</option>
                      <option value="thirty_day">30 Day Rate</option>
                    </select>
                  </div>

                  <div
                    className=" flex flex-row w-44 font-medium pl-3 py-1 mt-2 text-gray-700
                      mt-1 rounded-sm border-gray-100 border-2 "
                  >
                    <span className="pr-1">$</span>
                    <input
                      id="rate"
                      onChange={this.handleInputChange}
                      onWheel={(e) => {
                        e.currentTarget.blur();
                      }}
                      value={this.state.rate}
                      type="number"
                      className=" border-0 p-0 font-thin placeholder-gray-300 w-16 mr-1 text-gray-400 focus:border-0"
                      placeholder="0000.00"
                    />
                    <span className="pr-1">/ {this.getPricingType()} </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row pl-2 items-center mt-5 mb-5">
                <label className="block">
                  <div className="flex flex-row items-center justify-between ">
                    <span className=" transition-all duration-600 text-gray-500 text-s mr-3">
                      Is this a Rentals United Booking?{" "}
                      <span className="text-red-500">
                        [Do not touch this if you don't know what this is]
                      </span>
                    </span>
                    <ToggleSwitch
                      className="justify-center align-center"
                      size="lg"
                      onoff={this.state.rentalsUnitedToggle}
                      handleStateToggle={this.handleRentalsUnitedToggle}
                    />
                  </div>
                </label>
              </div>
              {this.state.rentalsUnitedToggle && (
                <div className="flex flex-row my-5 mt-8 ml-2  ">
                  <div className="flex flex-row items-center justify-between w-80 ">
                    <span className=" transition-all duration-600 text-gray-500 text-sm mr-3">
                      Rentals United Reservation ID
                    </span>
                    <input
                      id="rentalsUnitedReservationID"
                      onChange={this.handleInputChange}
                      onWheel={(e) => {
                        e.currentTarget.blur();
                      }}
                      type="number"
                      className="text-sm transition-all duration-600 font-normal text-gray-600
                        mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300
                        placeholder-gray-300 focus:ring-0 focus:border-gray-500"
                      placeholder="0"
                      value={this.state.rentalsUnitedReservationID}
                    />
                  </div>
                </div>
              )}
              <div
                className="  w-7/12 font-medium pl-3 py-1 text-gray-700  mt-5 rounded-sm
                border-gray-100 border-2 "
              >
                <span>Cleaning Frequency -</span>
                <select
                  id="cleaning_frequency"
                  onChange={this.handleInputChange}
                  value={this.state.cleaning_frequency}
                  className=" space-x-64  whitespace-nowrap mt-0 py-0 pl-1 w-auto border-0 text-gray-500
                    border-gray-200 focus:ring-0 focus:border-black"
                >
                  <option
                    value="bi_weekly"
                    selected={this.state.cleaning_frequency == "bi_weekly"}
                  >
                    Bi-Weekly
                  </option>
                  <option
                    value="weekly"
                    selected={this.state.cleaning_frequency == "weekly"}
                  >
                    Weekly
                  </option>
                  <option
                    value="none"
                    selected={this.state.cleaning_frequency == "none"}
                  >
                    Never
                  </option>
                </select>
              </div>

              <div className="flex flex-row my-5 mt-8 ml-2  ">
                <div className="flex flex-row items-center justify-between w-44 ">
                  <span className=" transition-all duration-600 text-gray-500 text-sm mr-3">
                    Pet Fee
                  </span>
                  <input
                    id="pet_fee"
                    onChange={this.handleInputChange}
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    type="number"
                    className="text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500"
                    placeholder="0"
                    value={this.state.pet_fee}
                  />
                </div>

                <div className="flex flex-row items-center justify-between w-44 ml-8 ">
                  <div className="flex flex-row">
                    <span className=" transition-all duration-600 text-gray-500 text-sm mr-3">
                      Hard Check Out
                    </span>
                    <ToggleSwitch
                      id="hard_checkout"
                      className="justify-center align-center"
                      size="lg"
                      onoff={this.state.hard_checkout}
                      handleStateToggle={this.handleToggleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row mt-5 mb-10 ml-2 mb-12   ">
                <div className="flex flex-row items-center justify-between w-44 ">
                  <span className=" transition-all duration-600 text-gray-500 text-sm mr-3">
                    Cleaning Fee
                  </span>
                  <input
                    id="cleaning_fee"
                    onChange={this.handleInputChange}
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    type="number"
                    className="text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300
                      placeholder-gray-300 focus:ring-0 focus:border-gray-500"
                    placeholder="0"
                    value={this.state.cleaning_fee}
                  />
                </div>

                <div className="flex flex-row items-center justify-between w-44 ml-8 mt-2 ">
                  <div className="flex flex-row">
                    <span className=" transition-all duration-600 text-gray-500 text-sm mr-1">
                      Parking Required?
                    </span>
                    <ToggleSwitch
                      className="justify-center align-center"
                      size="lg"
                      onoff={this.state.parkingRequired}
                      handleStateToggle={this.toggleParking}
                    />
                  </div>
                </div>
              </div>
              {this.state.parkingRequired && (
                <div>
                  <hr />
                  <div className="flex flex-col my-5">
                    <h1 className="pl-2 mb-2">Parking Information: </h1>
                    <div className="pl-2 flex flex-col w-52 mb-5">
                      <label className="block  ">
                        <label className="block  ">
                          <span className="text-green-400 text-sm">
                            Parking Spots*
                          </span>
                          <select
                            id="parking_spot_id"
                            onChange={this.handleInputChange}
                            className="block w-full text-sm  mt-1 rounded-sm border-gray-100 border-2
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          >
                            {this.state.activeParkingSpots.map(
                              (parkingSpot: any) => (
                                <option
                                  key={parkingSpot.id}
                                  value={parkingSpot.id}
                                >
                                  {" "}
                                  {parkingSpot.parking_spot_location} |{" "}
                                  {parkingSpot.parking_spot_level}{" "}
                                </option>
                              )
                            )}
                          </select>
                        </label>
                      </label>
                    </div>
                    <div className="flex flex-col w-44 ml-2">
                      <span className=" transition-all duration-600 text-green-400 text-sm mr-3">
                        Parking Fee (Per Night)
                      </span>
                      <input
                        id="parking_fee"
                        onChange={this.handleInputChange}
                        onWheel={(e) => {
                          e.currentTarget.blur();
                        }}
                        type="number"
                        className="text-sm transition-all duration-600 font-normal text-gray-600
                            mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-300
                            focus:ring-0 focus:border-gray-500"
                        placeholder="0"
                      />
                    </div>
                    <div className="flex flex-col w-7/12 mt-5 ml-2">
                      <span className=" transition-all duration-600 text-green-400 text-sm w-1/3 mt-3">
                        Parking Notes
                      </span>
                      <textarea
                        id="parking_notes"
                        className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows={3}
                        onChange={this.handleInputChange}
                        value={this.state.parking_notes}
                      ></textarea>
                    </div>
                  </div>
                  <hr />
                </div>
              )}

              <div className="flex flex-row pl-2 items-center mt-12 ">
                <label className="block">
                  <div className="flex flex-row items-center justify-between ">
                    <span className=" transition-all duration-600 text-gray-500 text-s mr-3">
                      Corporate Booking
                    </span>
                    <ToggleSwitch
                      className="justify-center align-center"
                      size="lg"
                      onoff={this.state.corporate}
                      handleStateToggle={this.handleCorporateToggle}
                    />
                  </div>
                </label>
              </div>

              <div
                className={`${this.getCorporateSectionLayout()} transition-all duration-500`}
              >
                <div className=" flex flex-row pl-2 items-center mt-10 mb-10 ">
                  <label className="block mr-6 w-44    ">
                    <label className="block  ">
                      <span className="text-gray-400 text-xs">
                        Corporations
                      </span>
                      <select
                        id="corporate_id"
                        value={this.state.corporate_id}
                        onChange={this.handleCorporateChange}
                        className="block w-full text-sm  mt-1 mb-4 rounded-sm border-gray-100 border-2
                          focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        placeholder=""
                      >
                        {this.createCorpItems()}
                        <option value="" selected disabled hidden>
                          Choose here
                        </option>
                      </select>
                      {fields}

                      <label className={"block mr-6 w-44    "}>
                        <input
                          id="corporate_deduction"
                          onWheel={(e) => {
                            e.currentTarget.blur();
                          }}
                          type="number"
                          className="  text-sm transition-all duration-600 font-normal text-gray-600 mt-0
                            py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-300
                            focus:ring-0 focus:border-gray-500"
                          // value={this.state.corporate_deduction}
                          onChange={this.handleInputChange}
                          placeholder="Corporate Deduction %"
                        />
                      </label>
                    </label>
                  </label>
                </div>
              </div>

              <div className="pl-0 mt-6 flex pt-4 flex-col self-start w-7/12 mb-5">
                <label className="block  ">
                  <span className="text-gray-400 text-sm ">
                    Additional Notes
                  </span>
                  <textarea
                    id="booking_notes"
                    onChange={this.handleInputChange}
                    value={this.state.booking_notes}
                    className=" w-7/12 text-sm transition-all duration-600 font-normal text-gray-600
                      mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-gray-300
                      focus:ring-0 focus:border-gray-500"
                  ></textarea>
                </label>
              </div>

              {/* Commented out, will be used in the future */}
              {/* <div
                className="  w-7/12 font-medium pl-3 py-1 mb-12 text-gray-700  mt-8 rounded-sm
                  border-gray-100 border-2 "
              >
                <span>Booking Expiry [In Beta, do not use] -</span>
                <select
                  id="deposit_expiry"
                  onChange={this.handleInputChange}
                  value={this.state.deposit_expiry}
                  className=" space-x-64  whitespace-nowrap mt-0 py-0 pl-1 w-auto border-0 text-gray-500
                    border-gray-200 focus:ring-0 focus:border-black"
                >
                  <option value={-1}>Never</option>
                  <option value={96}>96 Hours</option>
                  <option value={48}>48 Hours</option>
                  <option value={24}>24 Hours</option>
                </select>
              </div> */}

              <BookingSummary
                data={this.state}
                dayCount={dayCount}
                unit_id={this.state.unit_id}
                corporate_id={this.state.corporate_id}
                corporate_deduction={+this.state.corporate_deduction}
                openHighRisk={this.handleHighRiskTenant}
              />

              <Link to="/user/bookings/new/manual-booking">
                <button
                  className=" font-SF-Pro shadow-sm transition text-md duration-100 w-44 bg-gray-100
                  hover:bg-gray-200 text-gray-500 font-bold py-2 rounded-sm pr-1 flex flex-row justify-center
                  align-center mr-3"
                >
                  Back
                </button>
              </Link>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ManualInfo);
